@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,400;0,500;0,600;1,400&display=swap');

html {
  line-height: 1.5;
}

body {
  margin: 0;
  height: 100vh;
  display: grid;
  place-content: center;
  // background: rgba(0, 0, 0, 1);
  background: rgba(255, 255, 255, 0);
  font-family: 'Nunito';
}

h1 {
  font-size: 2.4rem;
  margin: 0px;
}

.onboard-container {
  border-radius: .7em;
  padding: 4em;
  background: rgba(255, 255, 255, 0);
  min-width: 500px;
  max-width: 800px;
  text-align: center;
  word-wrap: break-word;
}

/* .onboard {
  background-color: rgb(76, 0, 255);
  color: white;
  display: inline-block;
  text-decoration: none;
  padding: .7em;
  text-align: center;
  font-size: 1.3rem;
  border-radius: .3em;
  margin-top: 2em;
} */

.connected {
  font-family: 'COCOGOOSE', sans-serif;
  color: #386193;
  font-size: 35px;
}

.disconnect-btn {
  font-family: 'COCOGOOSE', sans-serif;
  background: transparent linear-gradient(181deg, #EB4F6F 0%, #AE0C32 100%) 0% 0% no-repeat padding-box;
  color: white;
  display: inline-block;
  text-decoration: none;
  padding: 0.9em;
  text-align: center;
  font-size: 1.1rem;
  border-radius: 35px;
  border: 0px;
  box-shadow: none;
}

/* p {
  font-size: 1.125rem;
} */

/* .loader {
  width: 200px;
  height: 200px;
  margin: 0 auto;
  display: none;
} */

.account {
  background: rgba(68, 126, 166, 0.3);
  padding: .5em;
  border-radius: 2em;
  position: relative;
  display: inline-block;
  text-indent: 1.2em;
  color: #386193;
}

.account:before {
  position: absolute;
  content: "";
  background: #00FFD9;
  width: .7em;
  height: .7em;
  border-radius: 50%;
  left: .5em;
  top: .9em;
}

/* .up {
  position: absolute;
  top: 2em;
  right: 2em;
  width: 10em;
  display: none;
} */

/* .confetti {
  position: absolute;
  width: 20em;
  z-index: 2;
  display: none;
} */

.walletconnect-qrcode__base {
  background: none !important;
}

.uYscM {
  background: rgba(255, 255, 255, 0) !important;
}

.walletconnect-modal__header {
  display: none !important;
}

.walletconnect-modal__base {
  font-family: 'COCOGOOSE', sans-serif;
  box-shadow: none !important;
  border-radius: 0.5rem !important;
  background-color: rgba(255, 255, 255, 0) !important;
}

#walletconnect-qrcode-text {
  display: none !important;
}

.walletconnect-modal__footer {
  display: none !important;
}

/* .walletconnect-modal__base:before {
  content: "Please scan QR code";
  font-family: 'COCOGOOSE', sans-serif;
  color: #386193;
  font-size: 35px;
  margin-bottom: 2rem;
} */

.walletconnect-qrcode__image {
  // width: 300px !important;
  width: 500px !important;
  background-color: #FFFFFF;
  padding: 10px;
}

/* #WEB3_CONNECT_MODAL_ID {
  display: none !important;
} */
